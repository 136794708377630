import Http from '@/services/SurveyHttp'

export default {
	/**
	 * Find or create submission
	 *
	 * @param {Object} data
	 */
	async findOrCreate (data) {
		try {
			const response = await Http.post('api/submissions/findOrCreate', data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Update submission and answers
	 *
	 * @param {Object} data
	 */
	async update (data) {
		try {
			const response = await Http.patch('api/submissions/update', data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	async setComplete(submission_id) {
		try {
			const response = await Http.patch(`api/submissions/${submission_id}/setComplete`, submission_id)
			return response.data
		} catch (err) {
			throw err
		}
	}
}

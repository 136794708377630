<template>
  <div class="google-maps-container" :class="{ isFullscreen: isFullscreen }">
    <div class="loader" v-show="loading">
      <v-progress-circular indeterminate size="32"></v-progress-circular>
    </div>
    <div id="google-map" class="google-map" :class="{ isFullscreen: isFullscreen }" v-show="!loading"></div>

    <button class="custom-fullscreen-control" v-show="!loading" @click="toggleFullscreen">
      <v-icon>{{ fullscreenIcon }}</v-icon>
    </button>
    <button v-if="this.isTapped && isFullscreen || this.markers.length > 1 && isFullscreen" class="info-button"
      :style="{ 'background-color': bgColor }" @click="toggleInfoModal">
      <v-icon color="white">mdi-information-outline</v-icon>
    </button>
    <div v-if="this.hasLocation" class="search-bar-container">
      <v-autocomplete @focus="clearInputOnFocus" ref="searchfield" v-model="searchModel" :items="searchOptions"
        item-text="name" item-value="id" :label="this.searchBoxText" :search-input.sync="searchChange"
        @change="selectedPlace" solo auto-select-first>
      </v-autocomplete>
    </div>
    <div class="help-message-container" :style="{ 'background-color': bgColor }"
      v-if="helpMessage != '' && isFullscreen && this.hasLocation">
      <v-icon color="white">mdi-information-outline</v-icon>
      <span class="help-message">{{ !isTapped && isFullscreen && this.markers.length < 1 ? this.mapInfoText
      :this.helpMessage }}</span>
    </div>
    <div class="info-dialog-wrap" :style="{ 'background-color': bgColor }" v-if="infoActive && isFullscreen">
      <div class="row">
        <div class="col-sm-12" :style="{ 'display': 'flex', 'justify-content': 'end' }">
          <button>
            <v-icon :style="{ 'padding-bottom': '10px' }" color="black" @click="toggleInfoModal">mdi-close</v-icon>
          </button>
        </div>
      </div>
      <div class="info-dialog-buttons">
        <div
          :style="{ 'margin-bottom': '10px', 'display': 'flex', 'justify-content': 'center', 'flex-flow': 'column' }">
          <button :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
            'padding': '5px', 'opacity:': ' 0.8', 'background-color': this.textToView == 1 ? 'green' : 'white', 'color': this.textToView == 1 ? 'white' : 'black'
          }" @click="toggleInfoText(1)">{{ this.tripDescriptionLabel }}</button>
          <div :style="{ 'background-color': 'white' }">
            <p :style="{ 'padding': '10px' }" v-if="this.textToView == 1">{{ this.tripDescriptionInstruction }}</p>
          </div>
        </div>
        <div
          :style="{ 'margin-bottom': '10px', 'display': 'flex', 'justify-content': 'center', 'flex-flow': 'column' }">
          <button :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
            'padding': '5px', 'background-color': this.textToView == 2 ? 'green' : 'white', 'color': this.textToView == 2 ? 'white' : 'black'
          }" @click="toggleInfoText(2)">{{ this.mapMoveLabel }}</button>
          <div :style="{ 'background-color': 'white' }">
            <p :style="{ 'padding': '10px' }" v-if="this.textToView == 2">{{ this.mapMoveInstruction }}</p>
          </div>
        </div>
        <div
          :style="{ 'margin-bottom': '10px', 'display': 'flex', 'justify-content': 'center', 'flex-flow': 'column' }">
          <button :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
            'padding': '5px', 'background-color': this.textToView == 3 ? 'green' : 'white', 'color': this.textToView == 3 ? 'white' : 'black'
          }" @click="toggleInfoText(3)">{{ this.startPointLabel }}</button>
          <div :style="{ 'background-color': 'white' }">
            <p :style="{ 'padding': '10px' }" v-if="this.textToView == 3">{{ this.startPointInstruction }}</p>
          </div>
        </div>
        <div
          :style="{ 'margin-bottom': '10px', 'display': 'flex', 'justify-content': 'center', 'flex-flow': 'column' }">
          <button :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
            'padding': '5px', 'background-color': this.textToView == 4 ? 'green' : 'white', 'color': this.textToView == 4 ? 'white' : 'black'
          }" @click="toggleInfoText(4)">{{ this.markerMoveLabel }}</button>
          <div :style="{ 'background-color': 'white' }">
            <p :style="{ 'padding': '10px' }" v-if="this.textToView == 4">{{ this.markerMoveInstruction }}</p>
          </div>
        </div>
        <div
          :style="{ 'margin-bottom': '10px', 'display': 'flex', 'justify-content': 'center', 'flex-flow': 'column' }">
          <button :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
            'padding': '5px', 'background-color': this.textToView == 5 ? 'green' : 'white', 'color': this.textToView == 5 ? 'white' : 'black'
          }" @click="toggleInfoText(5)">{{ this.markerDeleteLabel }}</button>
          <div :style="{ 'background-color': 'white' }">
            <p :style="{ 'padding': '10px' }" v-if="this.textToView == 5">{{ this.markerDeleteInstruction }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="initial-message-wrap" @click="closeInitialMessage"
      v-if="!isTapped && isFullscreen && this.markers.length < 1 && this.hasLocation">
      <!-- <div class="initial-message-container" :style="{ 'background-color': bgColor }">
        <v-icon color="white">mdi-information-outline</v-icon>
        <span class="help-message">{{ this.mapInfoText }}</span>
      </div> -->
      <v-icon class="assist-icon-tap" size="100">mdi-gesture-tap</v-icon>
    </div>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader'
import { mapGetters } from 'vuex'
import { translationMixins } from '@/mixins/translation-mixins'

export default {
  name: 'GoogleMap',
  mixins: [translationMixins],
  data() {
    return {
      textToView: 1,
      infoActive: false,
      isTapped: false,
      loading: true,
      map: null,
      geocoder: null,
      allMarkers: [],
      lines: null,
      isFullscreen: true,
      searchModel: "",
      searchChange: null,
      searchOptions: [],
      hasLocation: false
    }
  },
  props: {
    lastPlaceName: {
      type: String
    },
    bgColor: {
      type: String
    },
    canAddMarkers: {
      type: Boolean,
      required: true
    },
    markers: {
      type: Array,
      required: true
    },
    latestPoint: Object,
    locale: {
      type: String,
      default: 'fi'
    },
  },
  watch: {
    async searchChange(val) {
      if (val != null && val != "") {
        if (val.length > 3) {
          if (val != this.lastPlaceName) {
            await this.getPlaces(val)
          }
        }
      }
    },
    markers: function (value) {
      this.allMarkers.forEach(marker => {
        if (!this.markerIsShown(marker)) {
          marker.setMap(null)
        }
      })

      let coords = this.markers.map(marker => {
        return {
          lat: marker.lat,
          lng: marker.lng
        }
      })

      this.lines.setPath(coords)
      this.searchChange = this.lastPlaceName
    }
  },
  async mounted() {

    let location = await this.getLocation()
    this.hasLocation = true;
    this.loading = false

    const mapElement = document.getElementById('google-map')

    const options = {
      zoom: 5,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: window.google.maps.ControlPosition.TOP_LEFT
      },
      zoomControl: true,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM
      },
      scaleControl: true,
      streetViewControl: false,
      fullscreenControl: false,
      center: new window.google.maps.LatLng(location.lat, location.lng)
    }

    this.map = new window.google.maps.Map(mapElement, options)
    this.geocoder = new window.google.maps.Geocoder()

    // Initialize markers
    this.$props.markers.forEach((marker, index) => {
      let newMarker = new window.google.maps.Marker({
        map: this.map,
        position: {
          lat: marker.lat,
          lng: marker.lng,
        },
        draggable: true,
        label: this.getMarkerLetter(index),
      })
      var self = this
      newMarker.addListener('dragend', async function (mapsMouseEvent) {
        let place = await new Promise((resolve, reject) => {
          let coder = new window.google.maps.Geocoder
          coder.geocode({
            location: mapsMouseEvent.latLng,
          }, (results, status) => {
            if (status === 'OK') {
              if (results[0]) {
                let result = results[0].address_components
                if (!result) return ''
                let city = result.filter(res => {
                  return res.types.includes('locality')
                })

                if (city.length > 0) resolve(city[0].long_name)
                let political = result.filter(res => {
                  return res.types.includes('political')
                })

                if (political.length > 0) resolve(political[0].long_name)
              }
            }
            resolve('')
          })
        })
        self.$emit('marker-moved', newMarker, place)
      })
      newMarker.addListener('click', function () {
        self.$emit('marker-to-remove', newMarker)
      })
      this.allMarkers.push(newMarker)
    })

    let coords = this.markers.map(marker => {
      return {
        lat: marker.lat,
        lng: marker.lng
      }
    })

    this.lines = new window.google.maps.Polyline({
      path: coords,
      geodesic: false,
      strokeColor: '#E47E67',
      strokeOpacity: 1,
      strokeWeight: 2,
      map: this.map
    })
    let here = this
    this.map.addListener('drag', function () {
      if (here.$refs.searchfield && here.$refs.searchfield.isFocused) {
        here.$refs.searchfield.blur()
      }
    })
    this.map.addListener('click', async (mapsMouseEvent) => {
      if (here.$refs.searchfield && here.$refs.searchfield.isFocused) {
        here.$refs.searchfield.blur()
      }
      if (!this.isTapped) {
        this.isTapped = true
      }
      if (this.canAddMarkers) {
        // Get place name of the marker
        let place = await new Promise((resolve, reject) => {
          this.geocoder.geocode({
            location: mapsMouseEvent.latLng,
          }, (results, status) => {
            if (status === 'OK') {
              if (results[0]) {
                let result = results[0].address_components
                if (!result) return ''

                // Return city if found
                let city = result.filter(res => {
                  return res.types.includes('locality')
                })

                if (city.length > 0) resolve(city[0].long_name)

                // Should be country
                let political = result.filter(res => {
                  return res.types.includes('political')
                })

                if (political.length > 0) resolve(political[0].long_name)
              }
            }
            resolve('')
          })
        })

        // Create new marker to be shown
        let marker = new window.google.maps.Marker({
          map: this.map,
          draggable: true,
          position: mapsMouseEvent.latLng,
          label: this.getMarkerLetter(this.markers.length),
        })

        var self = this
        marker.addListener('dragend', async function (mapsMouseEvent) {
          // Get place name of the marker
          let place = await new Promise((resolve, reject) => {
            self.geocoder.geocode({
              location: mapsMouseEvent.latLng,
            }, (results, status) => {
              if (status === 'OK') {
                if (results[0]) {
                  let result = results[0].address_components
                  if (!result) return ''

                  // Return city if found
                  let city = result.filter(res => {
                    return res.types.includes('locality')
                  })

                  if (city.length > 0) resolve(city[0].long_name)

                  // Should be country
                  let political = result.filter(res => {
                    return res.types.includes('political')
                  })

                  if (political.length > 0) resolve(political[0].long_name)
                }
              }
              resolve('')
            })
          })

          self.$emit('marker-moved', marker, place)
        })
        // marker.addListener('click', async function (mapsMouseEvent) {
        //   // Get place name of the marker
        //   let place = await new Promise((resolve, reject) => {
        //     self.geocoder.geocode({
        //       location: mapsMouseEvent.latLng,
        //     }, (results, status) => {
        //       if (status === 'OK') {
        //         if (results[0]) {
        //           let result = results[0].address_components
        //           if (!result) return ''

        //           // Return city if found
        //           let city = result.filter(res => {
        //             return res.types.includes('locality')
        //           })

        //           if (city.length > 0) resolve(city[0].long_name)

        //           // Should be country
        //           let political = result.filter(res => {
        //             return res.types.includes('political')
        //           })

        //           if (political.length > 0) resolve(political[0].long_name)
        //         }
        //       }
        //       resolve('')
        //     })
        //   })

        //   // Create new marker to be shown
        //   let marker = new window.google.maps.Marker({
        //     map: self.map,
        //     position: mapsMouseEvent.latLng,
        //     draggable: true,
        //     label: self.getMarkerLetter(self.markers.length),
        //   })

        //   self.allMarkers.push(marker)

        //   self.$emit('marker-added', marker, place)
        // })
        marker.addListener('click', function () {
          self.$emit('marker-to-remove', marker)

        })
        this.allMarkers.push(marker)
        this.$emit('marker-added', marker, place)
      }
    })
  },
  components: {
    // Component
  },
  computed: {
    ...mapGetters('translations', {
      translations: 'translations',
    }),
    helpMessage() {
      if (this.latestPoint == null) {
        return this.staticTranslation('messages.mapFirstPoint')
      }
      return this.staticTranslation('messages.mapNextPoint')
    },
    searchBoxText() {
      return this.staticTranslation('texts.mapSearchText')
    },
    mapInfoText() {
      return this.staticTranslation('texts.mapInfoText')
    },
    fullscreenIcon() {
      return this.isFullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'
    },
    tripDescriptionLabel() {
      return this.staticTranslation('texts.tripDescriptionLabel')
    },
    tripDescriptionInstruction() {
      return this.staticTranslation('texts.tripDescriptionInstruction')
    },
    mapMoveLabel() {
      return this.staticTranslation('texts.mapMoveLabel')
    },
    mapMoveInstruction() {
      return this.staticTranslation('texts.mapMoveInstruction')
    },
    startPointLabel() {
      return this.staticTranslation('texts.startPointLabel')
    },
    startPointInstruction() {
      return this.staticTranslation('texts.startPointInstruction')
    },
    markerMoveLabel() {
      return this.staticTranslation('texts.markerMoveLabel')
    },
    markerMoveInstruction() {
      return this.staticTranslation('texts.markerMoveInstruction')
    },
    markerDeleteLabel() {
      return this.staticTranslation('texts.markerDeleteLabel')
    },
    markerDeleteInstruction() {
      return this.staticTranslation('texts.markerDeleteInstruction')
    }
  },
  methods: {
    toggleInfoText(val) {
      this.textToView = val;
    },
    toggleInfoModal() {
      this.infoActive = !this.infoActive
    },
    clearInputOnFocus() {
      this.searchChange = ""
      this.searchOptions = []
    },
    closeInitialMessage() {
      if (!this.isTapped) {
        this.isTapped = true
      }
    },
    async selectedPlace(event) {
      this.$refs.searchfield.blur()
      this.searchTerm = ""
      await new Promise((resolve, reject) => {
        var request = {
          placeId: event,
          fields: ['address_components', 'geometry']
        }
        var service = new google.maps.places.PlacesService(this.map);
        service.getDetails(request, (result, status) => {
          if (status == 'OK') {
            let place = ""
            result.address_components.forEach(function (val) {
              if (val.types.includes('political') && place == "") {
                place = val.short_name
              }
            })
            this.createMarkerFromSearch(result.geometry.location.lat(), result.geometry.location.lng(), place)
          }
        })
        resolve('')
      })
    },
    createMarkerFromSearch(lat, lng, name) {
      // Create new marker to be shown
      let marker = new window.google.maps.Marker({
        map: this.map,
        position: { lat: lat, lng: lng },
        draggable: true,
        label: this.getMarkerLetter(this.markers.length),
      })
      var self = this
      marker.addListener('click', function () {
        self.$emit('marker-to-remove', marker)

      })
      marker.addListener('dragend', async function (mapsMouseEvent) {
        let place = await new Promise((resolve, reject) => {
          let coder = new window.google.maps.Geocoder
          coder.geocode({
            location: mapsMouseEvent.latLng,
          }, (results, status) => {
            if (status === 'OK') {
              if (results[0]) {
                let result = results[0].address_components
                if (!result) return ''

                // Return city if found
                let city = result.filter(res => {
                  return res.types.includes('locality')
                })

                if (city.length > 0) resolve(city[0].long_name)

                // Should be country
                let political = result.filter(res => {
                  return res.types.includes('political')
                })

                if (political.length > 0) resolve(political[0].long_name)
              }
            }
            resolve('')
          })
        })

        self.$emit('marker-moved', marker, place)
      })
      this.allMarkers.push(marker)
      this.$emit('marker-added', marker, name)
      if (this.$refs.searchfield && this.$refs.searchfield.isFocused) {
        this.$refs.searchfield.blur()
      }
    },
    async getPlaces(searchTerm) {
      let res = []
      await new Promise((resolve, reject) => {
        var request = {
          input: searchTerm,
          // componentRestrictions: { country: 'fi' },
        };
        var service = new google.maps.places.AutocompleteService();
        service.getPlacePredictions(
          request
          , (results, status) => {
            if (status === 'OK') {
              results.forEach(element => {
                res.push({ name: element.description, id: element.place_id })
              })
            }
            resolve('')
          })
      })
      this.searchOptions = res
    },
    staticTranslation(key) {
      return this.getStaticTranslation(this.locale, key, this.translations, this.$t(key))
    },
    async getLocation() {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject)
      }).catch(err => {
        return {
          coords: {
            latitude: 60.192059,
            longitude: 24.945831
          }
        }
      })

      return {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
    },
    getMarkerLetter(index) {
      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      return index > alphabet.length - 1 ? index : alphabet.charAt(index)
    },
    markerIsShown(marker) {
      for (let index in this.markers) {
        if (this.markers[index].lat == marker.getPosition().lat() && this.markers[index].lng == marker.getPosition().lng()) return true
      }

      return false
    },
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen
    },
    openFullScreen() {
      this.isFullscreen = true
    },
    closeFullScreen() {
      this.isFullscreen = false
    },
  },
}

</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  height: 250px;
  align-items: center;
  justify-content: center;
}

.google-map {
  height: 250px;
  width: 100%;
  position: relative !important;

  &.isFullscreen {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    width: 100%;
    position: absolute !important;
    left: 0;
    top: 0;
    z-index: 5;
  }
}

.google-maps-container {
  position: relative;

  &.isFullscreen {
    position: static;

    .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
      right: 55px;
    }
  }
}

.custom-fullscreen-control {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background-color: white;
  z-index: 7;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
}

.info-button {
  z-index: 7;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  width: 40px;
  height: 40px;
  right: 50px;
  transform: translateX(-50%);
  color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.search-bar-container {
  z-index: 7;
  width: 100%;
  padding: 0 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 60px;
  max-width: 400px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
}

.info-dialog-buttons {
  display: flex;
  flex-flow: column;
  border-radius: 2px;
}

.info-dialog-wrap {
  padding: 10px 20px 20px 20px;
  z-index: 7;
  transform: translateX(-50%);
  max-width: 480px;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-top: 10px;
  top: 25%;
  left: 50%;
  border-radius: 2px;
}

.initial-message-wrap {
  z-index: 7;
  transform: translateX(-50%);
  max-width: 400px;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-top: 10px;
  top: 25%;
  left: 50%;
}

.assist-icon-tap {
  transform: translateX(-50%);
  left: 50%;
}

.initial-message-container {
  padding: 15px;
  display: flex;
  margin: 0 10px;
  color: white;
  border-radius: 5px;
}

.help-message-container {
  z-index: 7;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  max-width: 400px;
  left: 50%;
  transform: translateX(-50%);
  color: white;

  .help-message {
    margin-left: 10px;
  }
}
</style>

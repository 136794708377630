import SubmissionApi from '@/services/api/filler/SubmissionApi'
import Storage from '@/services/Storage'

export default {
	/**
	 * Find or create submission 
	 * @param {String} survey_id 
	 */
	async findOrCreate ({ commit }, survey_id) {
		try {
			const pathname = window.location.pathname.split('/')
			const target = pathname[1]
			const survey = pathname[2]
			let submission_id = null
			if (Storage.hasItem(`submission-${survey_id}`)) {
				const submission = Storage.getItem(`submission-${survey_id}`)
				if (submission.hasOwnProperty('id')) {
				submission_id = submission.id
				}
			}
			const data = await SubmissionApi.findOrCreate({
					id: submission_id,
					targetSlug: target,
					surveySlug: survey,
			})

			const submission = data.submission
			commit('setSubmission', submission)
			commit('setAnswers', data.answers)
			return data
		} catch (err) {
			throw err
		}	
	},
	/**
	 * Create new answer dat for field 
	 * @param {String} field_id
	 */
	createAnswer ({ state, getters, commit }, field_id) {
		const submission = getters['submission']
		const answer = {
			submission_id: submission.id,
			field_id: field_id,
			value: null,
			passages: [],
			options: [],
			option_id: null,
			parent_option_id: null,
			is_extra: false,
			is_done: false,
		}
		commit('addAnswer', answer)
	},
	/**
	 * description 
	 * @param {} 
	 */
	async update ({ state, getters, commit, rootGetters }, ) {
		let fieldAnswers = _.cloneDeep(getters['answers'])	
		let submission = getters['submission']
		let answers = Object.values(fieldAnswers)
		const response = await SubmissionApi.update({
			submission: submission,
			answers: answers
		})
	},
	/**
	 * Set submission complete 
	 */
	async setComplete ({ state, commit }, ) {
		const response = await SubmissionApi.setComplete(state.submission.id)
	},
}

import { getHappeningJumps } from '@/lib/survey-filler-helpers'

import Storage from '@/services/Storage'

export default {
	/**
	 * Set active segment as the one found in storage 
	 * @param {String} segment_id
	 */
	continue ({ commit, rootGetters, dispatch }, segment_id) {
		if (segment_id !== null) {
			const nextSegment = rootGetters['survey/getSegment'](segment_id)	
			//const fields = rootGetters['survey/segmentFields'](nextSegment.fields)
			dispatch('setFieldAnswers', nextSegment.fields)
			commit('setActiveSegment', nextSegment)
			commit('setHistory')
			commit('setActivePage', 'segment')
		}
	},
	/**
	 * Set history 
	 * @param {Array} history
	 */
	setHistory ({ commit }, history) {
		commit('setHistory', history)
	},
	/**
	 * Set age confirmation page active 
	 */
	goToAgeConfirm ({ commit }) {
		commit('setActivePage', 'age-confirmation')
	},
	/**
	 * Return tuo start page 
	 */
	cancel ({ commit }) {
		commit('setActivePage', 'start')
	},
	/**
	 * Start filling answers for survey 
	 */
	startSurvey ({ commit, dispatch, rootState, rootGetters }) {
		const nextSegment = rootGetters[`survey/segments`][0]
		//const fields = rootGetters['survey/segmentFields'](nextSegment.fields)
		dispatch('setFieldAnswers', nextSegment.fields)
		commit('setActiveSegment', nextSegment)
		commit('setActivePage', 'segment')
	},
	/**
	 * Validate active segment's fields 
	 */
	validateSegmentFields ({ commit, getters, rootGetters, dispatch }) {
		const activeSegment = getters.activeSegment	
		const answers = rootGetters['submission/answers'] 
		//const fields = rootGetters['survey/segmentFields'](activeSegment.fields) 
		activeSegment.fields.forEach(field => {
			if (field.is_required) {
				const answer = rootGetters['submission/getAnswer'](field.id)
				dispatch('validateField', {
					field: field,
					answer: answer
				})
			}
		})
	},
	/**
	 * Validate field and add not invalid list if not valid 
	 * @param {Object} payload field and answer
	 */
	validateField ({ commit }, payload) {
		const field = payload.field
		const answer = payload.answer

		if (field.answer_type === 'select') {
			if (answer.option_id === null) {
				commit('addFieldToInvalid', field.id)
			}
		} else if (field.answer_type === 'multiselect') {
			if (answer.options.length === 0) {
				commit('addFieldToInvalid', field.id)
			}
		} else if (
			field.answer_type === 'input'
			|| field.answer_type === 'date'
			|| field.answer_type === 'year'
			|| field.answer_type === 'country'
			|| field.answer_type === 'city'
			|| field.answer_type === 'slider'
	) {
			if (answer.value === null || answer.value === '') {
				commit('addFieldToInvalid', field.id)
			}
		}
	},
	/**
	 * Get next segment by answers and jumps 
	 */
	getNextSegment ({ state, commit, rootGetters }, ) {
		const activeSegment = state.activeSegment
		const jumps = rootGetters['survey/segmentJumps'](activeSegment.id)
		const answers = rootGetters['submission/answers'] 
		const happeningJumps = getHappeningJumps(jumps, answers)
		let nextSegment
		if (happeningJumps.length) {
			const nextSegmentId = happeningJumps[0].to_segment_id
			nextSegment = nextSegmentId !== null
				? rootGetters['survey/getSegment'](nextSegmentId) 
				: null
		} else {
			nextSegment = rootGetters['survey/getNextSegment'](activeSegment)
		}
		return nextSegment	
	},
	/**
	 * Send submission and set next segment as active 
	 */
	async next ({ state, commit, getters, rootGetters, dispatch }, ) {
		const activeSegment = getters.activeSegment	
		//If given answers are not valid
		if (getters.invalidFields.length > 0) {
			return 
		} else if (rootGetters['survey/isLastSegment'](activeSegment)) {
			//If is last segment set as complete 
			commit('submission/setAnswersAsNotExtra', activeSegment.fields, { root: true })
			await dispatch('submission/update', null, { root: true })
			await dispatch('submission/setComplete', null, { root: true })
			if(rootGetters['survey/data'].lottery_active){
				commit('setActivePage', 'lottery')
			}else{
				const survey = rootGetters['survey/data']
				let storageData = Storage.getItem(`submission-${survey.id}`);
				storageData.lottery_done = true;
				Storage.setItem(`submission-${survey.id}`, storageData)
				commit('setActivePage', 'end')
			}
		} else {
			commit('submission/setAnswersAsNotExtra', activeSegment.fields, { root: true })
			await dispatch('submission/update', null, { root: true })
			const nextSegment = await dispatch('getNextSegment')
			//If jump is not to the end of survey
			if (nextSegment !== null) {
				if (
					state.oldNextSegment
					&& nextSegment.id !== state.oldNextSegment.id
			) {
				//We are you using different jump than before
					await dispatch('removeAnswersBetween', {
						start: activeSegment.sort_order,
						end: nextSegment.sort_order 
					})
				}
				dispatch('setFieldAnswers', nextSegment.fields)
				commit('setActiveSegment', nextSegment)
				commit('addToHistory', activeSegment.id)
				dispatch('setActivePageToStorage', nextSegment.id)
			} else {
			//If nextSegment == null, route has changed because we can't return from null
				dispatch('removeAnswersBetween', {
					start: activeSegment.sort_order,
					end: null 
				})
				await dispatch('submission/setComplete', null, { root: true })
				commit('setActivePage', 'lottery')
			}
			//const fields = rootGetters['survey/segmentFields'](nextSegment.fields)
		}
	},
	/**
	 * Set previous segment as active 
	 */
	async previous ({ state, getters, commit, dispatch, rootGetters }, ) {
		if (getters.activePage === 'terms') {
				commit('setActivePage', 'start')
		} else if (getters.activePage === 'privacy') {
				commit('setActivePage', 'lottery')
		} else {
			commit('clearInvalid')
			if (state.history.length > 0) {
				const previousSegmentId = state.history[state.history.length - 1]	
				const previousSegment = rootGetters['survey/getSegment'](previousSegmentId) 
				commit('setActiveSegment', previousSegment)
				commit('removeLastFromHistory')
				dispatch('setActivePageToStorage', previousSegment.id)

				//Set oldNextSegment. We use this to compare if nextSegment changes.
				const nextSegment = await dispatch('getNextSegment')
				commit('setOldNextSegment', nextSegment)
			}
		}
	},
	/**
	 * Remove answers between nextSegment and activeSegment
	 * @example
	 * 1 -> 2 -> 3 -> 4 -> 5
	 * 2 <- 3 <- 4 <- 5
	 * 2 -> 5
	 * Remove 3 and 4 segment's answers
	 * 1 -> 2 -> [3 -> 4] -> 5
	 * @param {Object} payload 
	 */
	removeAnswersBetween ({ commit, rootGetters }, payload) {
		//Get segments which answers we want to remove
		const segments = rootGetters['survey/segments'].filter(segment => {
			if (payload.end === null) {
				return segment.sort_order > payload.start
			} else {
				return segment.sort_order > payload.start && segment.sort_order < payload.end
			}
		})	
		let fields = []
		segments.forEach(segment => {
			const fieldIds = segment.fields.map(field => field.id)
			fields = [...fields, ...fieldIds]
		})
		commit('submission/setAnswersAsExtra', fields, { root: true })
	},
	/**
	 * Set answers for fields if not yet created
	 * @param {Array} fields 
	 */
	setFieldAnswers({state, rootState, dispatch}, fields) {
		fields.forEach(field => {
			if (!rootState.submission.answers.hasOwnProperty(field.id)) {
				dispatch('submission/createAnswer', field.id, { root: true })
			}
		})
	},
	/**
	 * description
	 * @param {String} segment_id
	 */
	setActivePageToStorage ({state, rootGetters}, segment_id) {
			const survey = rootGetters['survey/data']
			const surveyId = survey.id
			let submission = Storage.getItem(`submission-${surveyId}`)	
			Storage.setItem(`submission-${surveyId}`, {
				...submission,
				activePage: segment_id,
				history: state.history
			})
	},
}
